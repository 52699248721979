<template>
  <div class="container-fluid p-4">
    <div class="d-flex justify-content-between">
      <h1>Manage Company</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <b-card class="bg-surface-2">
          <b-row no-gutters>
            <b-col>
              <b-input-group class="mb-1">
                <b-form-input
                  id="input-1"
                  v-model="$v.searchCompany.name.$model"
                  placeholder="Search by company name"
                  :state="validateFormSearch('name')"
                  aria-describedby="searchCompany.name_feedback"
                  v-on:keyup.enter="getData"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="invalid-feedback"
                  id="searchCompany.name_feedback"
                >
                  Your search must be at least 3 character.
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end mt-1">
            <b-button size="sm" variant="dark" @click="resetSearch" class="mr-1"
              >Clear</b-button
            >
            <b-button size="sm" variant="light" @click="getData">
              Search</b-button
            >
          </div>
        </b-card>
        <div class="d-flex justify-content-between pt-4 pb-2">
          <div class="d-flex justify-content-start">
            <b-button
              class="textColor"
              variant="primary"
              size="sm"
              v-b-modal.form-add-company
              @click="showAddCompanyForm"
            >
              <i class="fa fa-plus mr-1"></i>Add Company
            </b-button>
          </div>
          <!--          <div class="d-flex justify-content-end w-30 d-inline-block">-->
          <!--            <b-form-input-->
          <!--              id="search_bar"-->
          <!--              size="sm"-->
          <!--              type="text"-->
          <!--              placeholder="Search..."-->
          <!--              v-model="searchTerms"-->
          <!--            ></b-form-input>-->
          <!--          </div>-->
        </div>

        <div class="d-flex justify-content-between">
          <!-- Main table element -->
          <b-table
            striped
            :busy="busy"
            :items="companysList"
            :fields="companysTableFields"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="searchTerms"
            class="tableBorder"
            stacked="md"
            responsive
            small
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * perPage }}
            </template>
            <template #cell(name)="row">
              <!-- {{ row.item.name | humanizeText }} -->
              {{
                row.item.name.replace(
                  /(^\w{1})|(\s{1}\w{1})|(?:- |\d\. ).*/g,
                  (match) => match.toUpperCase()
                )
              }}
            </template>

            <template #cell(access_metadata.access_groups)="row">
              <div v-if="row.item.access_metadata.access_groups != undefined">
                <div v-if="row.item.access_metadata.access_groups.length > 0">
                  <div
                    v-for="data in row.item.access_metadata.access_groups"
                    :key="data.access_group"
                  >
                    {{ data.access_group | humanizeText }}
                  </div>
                </div>
                <div v-else>{{ "-" }}</div>
              </div>
              <div v-else>
                {{ "-" }}
              </div>
            </template>

            <template #cell(access_metadata.lift_access_level)="row">
              <div
                v-if="row.item.access_metadata.lift_access_level != undefined"
              >
                {{
                  row.item.access_metadata.lift_access_level.lift_access_level
                }}
                - {{ row.item.access_metadata.lift_access_level.description }}
              </div>
              <div v-else>
                {{ "-" }}
              </div>
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="secondary"
                size="sm"
                v-b-modal.form-update-company
                v-b-tooltip.hover
                title="Update Company"
                @click="showUpdateCompanyForm(row.item, row.index)"
                class="mr-1"
              >
                <i class="fa fa-edit"></i>
              </b-button>

              <!--              <b-button-->
              <!--                variant="secondary"-->
              <!--                size="sm"-->
              <!--                v-b-tooltip.hover-->
              <!--                title="Preferences Company"-->
              <!--                class="mr-1"-->
              <!--                @click="handleShowCompanyVisitPreference(row.item)"-->
              <!--                disabled-->
              <!--              >-->
              <!--                <i class="fas fa-cogs"></i>-->
              <!--              </b-button>-->

              <!--delete company-->
              <b-button
                variant="danger"
                size="sm"
                @click="confirmDelete(row.item, row.index)"
                v-b-tooltip.hover
                title="Delete Company"
                :disabled="row.item.is_disabled"
              >
                <i class="fa fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
        </div>
        <!--pagination -->
        <div class="d-flex justify-content-between" v-if="!busy && !search">
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              @change="handlePerPageChange"
            ></b-form-select>
            <div class="d-flex w-100">Per Page</div>
          </div>

          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
      <!-- Modal -->
      <!-- add company-->
      <b-modal
        id="form-add-company"
        title="Add Company"
        ref="formAddCompany"
        centered
        hide-footer
      >
        <b-form @submit="onSubmitAddForm">
          <b-form-group
            id="input-group-name"
            label="Full name:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="formAdd.name"
              type="text"
              placeholder="Enter name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-access-level"
            label="Access Level:"
            label-for="input-access-level"
          >
            <multiselect
              placeholder="Choose access level"
              v-model="formAdd.access_metadata.access_level"
              label="access_level"
              track-by="access_level"
              :options="options_access_level"
            ></multiselect>
          </b-form-group>

          <b-form-group
            id="input-group-access-groups"
            label="Access Groups:"
            label-for="input-access-groups"
          >
            <multiselect
              placeholder="Choose access group"
              v-model="formAdd.access_metadata.access_groups"
              label="access_group"
              track-by="access_group"
              :options="options_access_groups"
              :multiple="true"
            ></multiselect>
          </b-form-group>

          <b-form-group
            id="input-group-access-groups"
            label="Lift Access Level:"
            label-for="input-access-groups"
          >
            <multiselect
              placeholder="Choose lift access level"
              v-model="formAdd.access_metadata.lift_access_level"
              label="description"
              track-by="lift_access_level"
              :custom-label="customLabel"
              :options="options_lift_access_levels"
              :multiple="false"
              :allow-empty="false"
            ></multiselect>
          </b-form-group>

          <div class="text-danger" v-if="formAddErrorMessage">
            {{ formAddErrorMessage }}
          </div>
          <div class="text-right">
            <b-button type="submit" variant="primary">Submit</b-button>
          </div>
        </b-form>
      </b-modal>

      <!-- update company-->
      <b-modal
        title="Update Company"
        id="form-update-company"
        ref="formUpdateCompany"
        centered
        hide-footer
      >
        <b-form @submit="onSubmitUpdateForm">
          <b-form-group
            id="input-group-name"
            label="Full name:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="formUpdate.name"
              type="text"
              placeholder="Enter name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-access-level"
            label="Access Level:"
            label-for="input-access-level"
          >
            <multiselect
              placeholder="Choose access level"
              v-model="formUpdate.access_metadata.access_level"
              label="access_level"
              track-by="access_level"
              :options="options_access_level"
            ></multiselect>
          </b-form-group>

          <b-form-group
            id="input-group-access-groups"
            label="Access Groups:"
            label-for="input-access-groups"
          >
            <multiselect
              placeholder="Choose access group"
              v-model="formUpdate.access_metadata.access_groups"
              label="access_group"
              track-by="access_group"
              :options="options_access_groups"
              :multiple="true"
            ></multiselect>
          </b-form-group>

          <b-form-group
            id="input-group-access-groups"
            label="Lift Access Level:"
            label-for="input-access-groups"
          >
            <multiselect
              placeholder="Choose lift access level"
              v-model="formUpdate.access_metadata.lift_access_level"
              label="description"
              track-by="lift_access_level"
              :custom-label="customLabel"
              :options="options_lift_access_levels"
              :multiple="false"
              :allow-empty="false"
            ></multiselect>
          </b-form-group>

          <div class="text-danger" v-if="formUpdateErrorMessage">
            {{ formUpdateErrorMessage }}
          </div>
          <div class="text-right">
            <b-button type="submit" variant="primary">Submit</b-button>
          </div>
        </b-form>
      </b-modal>

      <!-- show preferences modal -->
      <b-modal
        title="Company Visit Preference"
        ref="companyVisitPreferenceModal"
        centered
        hide-footer
      >
        <div v-if="useGlobalVisitPreference">
          This company is using global visit preference
        </div>
        <div v-else>This company has a custom visit preference.</div>

        <div class="mt-3"></div>

        <div>
          <div>
            <div class="pt-3">
              <div>Visit Start Time</div>
              <div class="h5">
                {{ displayVisitPreference.visit_start_time | dashForEmptyText }}
              </div>
            </div>

            <div class="pt-3">
              <div>Visit End Time</div>
              <div class="h5">
                {{ displayVisitPreference.visit_end_time | dashForEmptyText }}
              </div>
            </div>

            <div class="pt-3">
              <div>Tolerance Time In (Min)</div>
              <div class="h5">
                {{ displayVisitPreference.tolerance_time_in }}
              </div>
            </div>

            <div class="pt-3">
              <div>Tolerance Time Out (Min)</div>
              <div class="h5">
                {{ displayVisitPreference.tolerance_time_out }}
              </div>
            </div>

            <div class="pt-3">
              <div>Forms</div>

              <div class="d-flex">
                <div
                  class="h5 mr-1"
                  v-for="form in displayVisitPreference.formsList"
                  :key="form.id"
                >
                  <b-badge v-if="form.enable" variant="info"
                    >{{ form.name | dashForEmptyText }}
                  </b-badge>

                  <b-badge v-if="!form.enable" variant="danger"
                    >{{ form.name | dashForEmptyText }}
                  </b-badge>
                </div>

                <div v-if="displayVisitPreference.formsList.length === 0">
                  -
                </div>
              </div>
            </div>

            <div class="pt-3">
              <div>Created At</div>
              <div class="h5">
                {{ displayVisitPreference.created | dateTime }}
              </div>
            </div>

            <div class="pt-3">
              <div>Last Modified</div>
              <div class="h5">
                {{ displayVisitPreference.modified | dateTime }}
              </div>
            </div>
          </div>

          <div class="mt-5"></div>
          <b-button
            variant="primary"
            @click="handleAddCustomVisitPreference"
            v-if="useGlobalVisitPreference"
            >Edit
          </b-button>
          <b-button
            variant="primary"
            @click="handleEditCustomVisitPreference"
            v-if="!useGlobalVisitPreference"
            class="mr-1"
            >Edit Custom
          </b-button>
          <b-button
            variant="danger"
            @click="handleRemoveCustomVisitPreference"
            v-if="!useGlobalVisitPreference"
            >Reset to Global Preference
          </b-button>
        </div>
      </b-modal>

      <!-- edit custom preferences modal -->
      <b-modal
        title="Custom Visit Preference"
        ref="companyCustomVisitPreferenceModalForm"
        centered
        hide-footer
      >
        <div>
          <b-form @submit="onSubmitUpdateCustomVisitPreferenceForm">
            <b-form-group
              label="Visit Start Date:"
              label-for="visit_start_time"
            >
              <b-time
                id="visit_start_time"
                v-model="visitPreferenceForm.visit_start_time"
                show-seconds
                locale="en"
              ></b-time>
            </b-form-group>

            <b-form-group label="Visit End Date:" label-for="visit_end_time">
              <b-time
                id="visit_end_time"
                v-model="visitPreferenceForm.visit_end_time"
                show-seconds
                locale="en"
              ></b-time>
            </b-form-group>

            <b-form-group
              label="Tolerance Time In (Min):"
              label-for="tolerance_time_in"
            >
              <b-form-input
                id="tolerance_time_in"
                type="number"
                v-model="visitPreferenceForm.tolerance_time_in"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Tolerance Time Out (Min):"
              label-for="tolerance_time_out"
            >
              <b-form-input
                id="tolerance_time_out"
                type="number"
                v-model="visitPreferenceForm.tolerance_time_out"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Forms:">
              <multiselect
                v-model="visitPreferenceForm.formsList"
                :options="getForms"
                placeholder="Select form"
                label="name"
                track-by="name"
                :multiple="true"
              ></multiselect>
            </b-form-group>

            <b-button type="submit" variant="primary">Update</b-button>
          </b-form>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: {
    Multiselect
  },
  data: function () {
    return {
      /// start section
      companysList: [],
      companyData: [],
      companysTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "access_metadata.access_groups",
          label: "Access Groups",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "access_metadata.lift_access_level",
          label: "Lift Access Level",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions", thStyle: { width: "auto" } }
      ],
      preferencesList: [],

      options_access_level: [],
      options_access_groups: [],
      options_lift_access_levels: [],

      busy: false,
      searchTerms: "",
      /// end section

      formAddID: null,
      formAddErrorMessage: null,

      formUpdateID: null,
      formUpdateErrorMessage: null,

      formAdd: {
        name: null,
        profile: {},
        access_metadata: {
          access_level: [],
          access_groups: [],
          lift_access_level: []
        },
        additional_metadata: {}
      },
      formUpdate: {
        name: null,
        profile: {},
        access_metadata: {
          access_level: [],
          access_groups: [],
          lift_access_level: []
        },
        additional_metadata: {}
      },
      // search
      search: false,
      searchCompany: {
        name: null
      },
      disableCompanyList: [],
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      //pagination
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],

      // display preference
      selectedCompany: null,

      displayVisitPreference: {
        tolerance_time_out: null,
        id: null,
        visit_start_time: null,
        visit_end_time: null,
        forms: {},
        formsList: [],
        created: null,
        tolerance_time_in: null,
        forms_compulsory_fields: {},
        additional_metadata: {},
        modified: null
      },

      useGlobalVisitPreference: true,

      visitPreferenceForm: {
        tolerance_time_out: null,
        id: null,
        visit_start_time: null,
        visit_end_time: null,
        forms: {},
        formsList: [],
        created: null,
        tolerance_time_in: null,
        forms_compulsory_fields: {},
        additional_metadata: {},
        modified: null
      },

      customFormsList: [],

      formAccess: {
        access_types: [],
        access_groups: [],
        access_level: null,
        access_card_no: null,
        selected_acs_url: null
      },

      acsUrls: [],
      all_access_groups: {},
      all_access_levels: {},
      all_lift_access_levels: {}
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getForms: "psim/getForms"
    })
  },
  watch: {
    $route: function () {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 10;
      this.getCompanysList();
    }
  },
  created() {
    this.currentPage = this.$route.query.page
      ? parseInt(this.$route.query.page)
      : 1;
    this.perPage = this.$route.query.per_page
      ? parseInt(this.$route.query.per_page)
      : 10;
  },
  mounted: function () {
    this.getCompanysList();
    this.fetchForms();
    this.getAllVmsPreferences();
    this.access();
  },
  validations: {
    searchCompany: {
      name: { minLength: minLength(3) }
    }
  },
  methods: {
    async access() {
      try {
        let p1Urls = await this.fetchP1Members();
        this.acsUrls = p1Urls;

        for (let i = 0; i < this.acsUrls.length; i++) {
          this.$set(
            this.all_access_groups,
            this.acsUrls[i],
            await this.fetchAccessGroup(this.acsUrls[i])
          );

          this.$set(
            this.all_access_levels,
            this.acsUrls[i],
            await this.fetchAccessLevel(this.acsUrls[i])
          );

          this.$set(
            this.all_lift_access_levels,
            this.acsUrls[i],
            await this.fetchLiftAccessLevel(this.acsUrls[i])
          );
        }

        this.formAccess.selected_acs_url = this.acsUrls[0];
        this.formAccess.access_groups =
          this.all_access_groups[this.formAccess.selected_acs_url];
        this.options_access_groups = this.formAccess.access_groups;

        console.log(
          " this.formAccess.access_groups",
          this.formAccess.access_groups
        );

        this.formAccess.access_level =
          this.all_access_levels[this.formAccess.selected_acs_url];
        this.options_access_level = this.formAccess.access_level;

        for (let key2 in this.options_access_level) {
          if (this.options_access_level[key2].access_level == "1") {
            this.formAdd.access_metadata.access_level =
              this.options_access_level[key2];
            this.formUpdate.access_metadata.access_level =
              this.options_access_level[key2];
          }
        }

        this.formAccess.lift_access_level =
          this.all_lift_access_levels[this.formAccess.selected_acs_url];
        this.options_lift_access_levels = this.formAccess.lift_access_level;

        for (let key4 in this.options_lift_access_levels) {
          if (this.options_lift_access_levels[key4].lift_access_level == "0") {
            this.formAdd.access_metadata.lift_access_level =
              this.options_lift_access_levels[key4];
            this.formUpdate.access_metadata.lift_access_level =
              this.options_lift_access_levels[key4];
          }
        }

        console.log(
          "this.formAccess.lift_access_level",
          this.formAccess.lift_access_level
        );
      } catch (e) {
        console.log(e);
      }
    },
    async fetchP1Members() {
      let API_URL =
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl].result;
      }
      return [];
    },
    async fetchAccessGroup(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-groups/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let error = res.detail;
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }
      if (error != undefined) {
        error =
          "P1 is not connected. Please contact your system administrator. " +
          "If P1 is already connected, you should refresh the browser to be able to fill in the form again.";
        alert(error);
        return;
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },
    async fetchAccessLevel(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-levels/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },
    async fetchLiftAccessLevel(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/lift-access-levels/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },

    customLabel({ description, lift_access_level }) {
      return `${lift_access_level} - ${description}`;
    },

    getCompanysList: function () {
      let $this = this;
      $this.busy = true;
      $this.companysList = [];
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/company/all/";
      const client = $this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL, {
          page_index: $this.currentPage,
          page_size: $this.perPage
        })
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          // if (Object.keys(result).length !== 0) {
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                $this.busy = false;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] != undefined) {
            let id = null;
            for (let key2 in result[validUrl]) {
              for (let key3 in result[validUrl][key2]) {
                if (Object.keys(result[validUrl][key2][key3]).length !== 0) {
                  result[validUrl][key2][key3]["is_disabled"] = null;
                  id = result[validUrl][key2][key3].id;
                  this.fetchCompany(id, result[validUrl][key2][key3]);
                } else {
                  result[validUrl][key2]["is_disabled"] = null;
                  id = result[validUrl][key2].id;
                  this.fetchCompany(id, result[validUrl][key2]);
                }
              }

              //this.companysList.push(result[validUrl][key2]);
            }
          } else {
            //
            $this.companysList = [];
          }
          // } else {
          //   $this.busy = false;
          // }

          if (result[validUrl]["total"] !== undefined) {
            $this.totalRows = parseInt(result[validUrl]["total"]);
          }

          if (result[validUrl]["page"] !== undefined) {
            $this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            $this.perPage = parseInt(result[validUrl]["size"]);
          }

          console.log("totalRows Host", this.totalRows);
          console.log("currentPage Host", this.currentPage);
          console.log("perPage HOst", this.perPage);
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    /* comp_list(data, company_data) {
      let validUrl = "";
      let result = data.result;
      if (result != undefined) {
        outer_loop: for (let key in result) {
          if (result[key] != undefined && Array.isArray(result[key])) {
            validUrl = key;
            var company = result[validUrl];
            break outer_loop;
          }
        }
      }
      if (company.length > 0) {
        company_data["is_disabled"] = true;
      } else {
        company_data["is_disabled"] = false;
      }
    }, */

    /* disabled(item_data) {
      if(item_data.host == null) {
        return false;
      } else {
        return true;
      }
    }, */
    fetchCompany(id, company_data) {
      //let id = item;
      let $this = this;
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/company/" + id + "/hosts/";
      const client = this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL)
        .then((data) => {
          /* for (let key in result) {
              this.comp_list(data, company_list);
            } */
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined && Array.isArray(result[key])) {
                validUrl = key;
                //var company = result[validUrl];
                break outer_loop;
              }
            }
          }
          if (result[validUrl].length > 0) {
            company_data.is_disabled = true;
          } else {
            company_data.is_disabled = false;
          }

          this.companysList.push(company_data);
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    getAllVmsPreferences: function () {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/api/visitorservice/visit_preference/all/";
      const client = $this.$root.getAjaxFetchClient();
      client.getRequest(API_URL).then((data) => {
        let validUrl = "";
        let result = data.result;

        if (result != undefined) {
          outer_loop: for (let key in result) {
            if (result[key] != undefined && Array.isArray(result[key])) {
              validUrl = key;
              $this.busy = false;
              break outer_loop;
            }
          }
        }
        if (result[validUrl] != undefined && Array.isArray(result[validUrl])) {
          this.preferencesList = result[validUrl];
        }

        console.log("getAllVmsPreferences data", this.preferencesList);
      });
    },

    fetchForms: function () {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/form/all/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let formsList = null;
          if (data != undefined) {
            for (let url in data.result) {
              formsList = data.result[url];
            }
            for (let i in formsList) {
              var w_data = {
                id: formsList[i].id,
                name: formsList[i].name,
                index: formsList[i].index,
                form_data: formsList[i].form_data,
                enable: formsList[i].enable
              };

              $this.customFormsList.push(w_data);
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    dateTimeFormatter(datatime) {
      return moment(datatime).format("DD/MM/YYYY HH:mm:ss");
    },
    displayDataFormatter(data, item) {
      if (data === "" || data === null) {
        return "-";
      } else {
        let minutes = 0;
        let duration = null;
        if (item == "tolerance_time_in") {
          minutes = parseInt(data);
          if ((minutes > 59 && minutes < 100) || minutes < 1440) {
            duration = moment.duration(minutes, "minutes");
            let tolerance_time_in = moment()
              .hours(duration._data.hours)
              .minutes(duration._data.minutes)
              .seconds(duration._data.seconds)
              .format("HH:mm:ss");
            return tolerance_time_in;
          } else if (minutes > 1439) {
            //
            duration = moment.duration(minutes, "minutes");
            let tolerance_time_in = moment()
              .days(duration._data.days)
              .hours(duration._data.hours)
              .minutes(duration._data.minutes)
              .seconds(duration._data.seconds)
              .format("HH:mm:ss");
            return tolerance_time_in;
          } else {
            let tolerance_time_in = moment(data, "minutes")
              .minutes(data)
              .format("HH:mm:ss");

            return tolerance_time_in;
          }
        } else if (item == "tolerance_time_out") {
          minutes = parseInt(data);

          if ((minutes > 59 && minutes < 100) || minutes < 1440) {
            //
            duration = moment.duration(minutes, "minutes");
            let tolerance_time_out = moment()
              .hours(duration._data.hours)
              .minutes(duration._data.minutes)
              .seconds(duration._data.seconds)
              .format("HH:mm:ss");
            return tolerance_time_out;
          } else if (minutes > 1439) {
            //
            duration = moment.duration(minutes, "minutes");
            let tolerance_time_out = moment()
              .days(duration._data.days)
              .hours(duration._data.hours)
              .minutes(duration._data.minutes)
              .seconds(duration._data.seconds)
              .format("HH:mm:ss");
            return tolerance_time_out;
          } else {
            let tolerance_time_out = moment(data, "minutes")
              .minutes(data)
              .format("HH:mm:ss");

            return tolerance_time_out;
          }
        } else {
          return data;
        }
      }
    },

    showAddCompanyForm: function () {
      let $this = this;
      $this.formAdd.name = null;
    },

    showUpdateCompanyForm: function (item, index) {
      let $this = this;
      $this.formUpdateID = item.id;
      $this.formUpdate.name = item.name;
      $this.formUpdate.access_metadata.access_groups =
        item.access_metadata.access_groups;
      if (item.access_metadata.lift_access_level != undefined) {
        $this.formUpdate.access_metadata.lift_access_level =
          item.access_metadata.lift_access_level;
      }

      $this.formUpdateErrorMessage = null;
    },
    // onSubmitAddCompany
    onSubmitAddForm: function (e) {
      e.preventDefault();
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/company/";
      let index;
      outer_loop: for (let key in $this.preferencesList) {
        if ($this.preferencesList[key] != undefined) {
          index = key;
          break outer_loop;
        }
      }

      if (
        $this.preferencesList[index].additional_metadata
          .vms_preferences_access_groups != undefined &&
        $this.preferencesList[index].additional_metadata
          .vms_preferences_access_groups.length == 0 &&
        $this.formAdd.access_metadata.access_groups != undefined &&
        $this.formAdd.access_metadata.access_groups.length == 0
      ) {
        $this.$refs.formAddCompany.hide();
        this.$bvModal.msgBoxOk(
          "Please setup for the company group access in SmartVMS settings.",
          {
            centered: true
          }
        );
        return false;
      } else {
        console.log("$this.formAdd", $this.formAdd);
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, $this.formAdd)
          .then((data) => {
            if (data.detail != undefined) {
              $this.formAddErrorMessage = data.detail;
            } else {
              outer_loop: for (let key in data.result) {
                if (data.result[key] != undefined) {
                  $this.formAddID = data.result[key].company_id;
                  break outer_loop;
                }
              }
              let API_URL_2 =
                $this.getAPIServerURL +
                "/api/visitorservice/company/?company_id=" +
                $this.formAddID;
              const client = $this.$root.getAjaxFetchClient();
              client
                .getRequest(API_URL_2)
                .then((data) => {
                  let addCompany;
                  outer_loop: for (let key in data.result) {
                    if (data.result[key] != undefined) {
                      addCompany = data.result[key];
                      break outer_loop;
                    }
                  }

                  $this.companysList.push(addCompany);
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text:
                      "Company " +
                      addCompany.name +
                      " has been added successfully",
                    message_type: "success"
                  });

                  $this.formAdd.name = null;
                  $this.formAddErrorMessage = null;
                  $this.formUpdateErrorMessage = null;

                  $this.$refs.formAddCompany.hide();
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      }
    },
    onSubmitUpdateForm: function (e) {
      let $this = this;
      e.preventDefault();

      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/company/?company_id=" +
        $this.formUpdateID;
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdate)
        .then(() => {
          $this.getCompanysList();
          $this.$refs.formUpdateCompany.hide();
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Updated company successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteCompany: function (company_id) {
      let $this = this;
      let data = {
        previous_data: {}
      };
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/company/?company_id=" +
        company_id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, data)
        .then(() => {
          $this.getCompanysList();
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted company successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //pagination
    //function paging
    onFiltered: function (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handlePageChange(page) {
      console.log("updated page", page);

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      console.log("updated pageSize", pageSize);

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          per_page: parseInt(pageSize)
        }
      });
    },
    // confirm  delete
    confirmDelete: function (company_data, index) {
      // e.preventDefault();
      let $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + company_data.name + "?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            $this.deleteCompany(company_data.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    handleShowCompanyVisitPreference(company_data) {
      console.log(
        "handleShowCompanyVisitPreference company_data",
        company_data
      );

      this.selectedCompany = company_data;

      console.log("preference", this.preferencesList[0]);
      this.$refs["companyVisitPreferenceModal"].show();

      let useGlobalFlag = true;

      try {
        if (
          Object.prototype.hasOwnProperty.call(
            company_data,
            "additional_metadata"
          )
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              company_data.additional_metadata,
              "visit_preference"
            )
          ) {
            useGlobalFlag = false;
          }
        }
      } catch (e) {
        console.log("e");
        console.log(e);
      }

      if (useGlobalFlag) {
        this.useGlobalVisitPreference = true;
        this.displayVisitPreference = this.preferencesList[0];
      } else {
        this.useGlobalVisitPreference = false;
        this.displayVisitPreference =
          company_data.additional_metadata.visit_preference;
      }

      let formList = [];

      for (let formId in this.displayVisitPreference.forms) {
        for (let key in this.customFormsList) {
          if (formId === this.customFormsList[key].id) {
            this.displayVisitPreference.forms[formId]["enable"] =
              this.customFormsList[key].enable;
          }
        }
        formList.push(this.displayVisitPreference.forms[formId]);
      }

      this.displayVisitPreference = {
        ...this.displayVisitPreference,
        formsList: formList
      };
    },

    handleAddCustomVisitPreference() {
      let that = this;

      this.$bvModal
        .msgBoxConfirm(
          `Are you confirm add custom visit preference for ${this.selectedCompany.name} ?`,
          {
            okTitle: "Yes",
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            that.visitPreferenceForm = that.displayVisitPreference;

            that.$refs["companyCustomVisitPreferenceModalForm"].show();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    handleEditCustomVisitPreference() {
      let that = this;

      this.$bvModal
        .msgBoxConfirm(
          `Are you confirm edit custom visit preference for ${this.selectedCompany.name} ?`,
          {
            okTitle: "Yes",
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            that.visitPreferenceForm = that.displayVisitPreference;

            let formList = [];

            for (let formId in that.visitPreferenceForm.forms) {
              for (let key in that.customFormsList) {
                if (formId == that.customFormsList[key].id) {
                  that.visitPreferenceForm.forms[formId]["enable"] =
                    that.customFormsList[key].enable;
                }
              }
              formList.push(that.visitPreferenceForm.forms[formId]);
            }

            that.visitPreferenceForm = {
              ...that.visitPreferenceForm,
              formsList: formList
            };

            that.$refs["companyCustomVisitPreferenceModalForm"].show();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    handleRemoveCustomVisitPreference() {
      let $this = this;

      this.$bvModal
        .msgBoxConfirm(
          `Are you confirm remove custom visit preference for ${this.selectedCompany.name} and use back global visit preference instead?`,
          {
            okTitle: "Yes",
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            let data = {
              additional_metadata: {}
            };

            let API_URL =
              $this.getAPIServerURL +
              "/api/visitorservice/company/?company_id=" +
              $this.selectedCompany.id;
            const client = $this.$root.getAjaxFetchClient();
            client
              .putRequest(API_URL, data)
              .then(() => {
                $this.getCompanysList();
                $this.$refs.formUpdateCompany.hide();
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Updated company successfully",
                  message_type: "success"
                });

                $this.$refs["companyCustomVisitPreferenceModalForm"].hide();
                $this.$refs["companyVisitPreferenceModal"].hide();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    onSubmitUpdateCustomVisitPreferenceForm: function (e) {
      e.preventDefault();

      let $this = this;

      let formsObj = {};

      for (let i = 0; i < $this.visitPreferenceForm.formsList.length; i++) {
        let singleFormObj = $this.visitPreferenceForm.formsList[i];
        formsObj[singleFormObj.id] = singleFormObj;
      }

      $this.visitPreferenceForm = {
        ...$this.visitPreferenceForm,
        forms: formsObj
      };

      let data = {
        additional_metadata: {
          visit_preference: this.visitPreferenceForm
        }
      };

      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/company/?company_id=" +
        $this.selectedCompany.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, data)
        .then(() => {
          $this.getCompanysList();
          $this.$refs.formUpdateCompany.hide();
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Updated company successfully",
            message_type: "success"
          });

          $this.$refs["companyCustomVisitPreferenceModalForm"].hide();
          $this.$refs["companyVisitPreferenceModal"].hide();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateFormSearch(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.searchCompany[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    getData: function () {
      let $this = this;
      $this.busy = true;

      let query = {
        name: $this.searchCompany.name
      };

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL =
        $this.getAPIServerURL + "/api/visitorservice/company/search/?";

      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      if (queryString != "") {
        API_URL += queryString;
        try {
          const client = $this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((data) => {
              let result = data.result;
              if (result != undefined) {
                for (let company_data in result) {
                  $this.companysList = result[company_data];
                  $this.busy = false;
                  $this.search = true;
                }
              } else {
                $this.companysList = [];
                $this.busy = false;
                $this.search = true;
              }
            })
            .catch((err) => {
              $this.busy = false;
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
            });
        } catch (e) {
          console.log("e");
          console.log(e);
        }
      } else {
        this.getCompanysList();
        this.search = true;
        this.busy = false;
      }
    },
    resetSearch: function () {
      this.search = false;
      this.searchCompany = {
        name: null
      };

      this.getCompanysList();
      this.$v.$reset();
    }
  }
};
</script>

<style lang="scss">
@import "./App.scss";
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
